.container {
  overflow: hidden;
  margin: -1rem;
}

.slide {
  margin-top: 1rem;
}

.slide button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: var(--radius);
  padding: 1rem;
  width: 100%;
  border: 0;
  margin: 0;
  background: transparent;
  color: var(--color-black);
  flex-direction: column;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
  filter: grayscale(100%);
}
.slide button:hover,
.slide button:focus {
  background: var(--color-black-translucent);
  z-index: 999;
}

.slide button figure {
  width: 7rem;
  height: 7rem;
  z-index: 0;
  border-radius: 50%;
  transition: 0.3s all;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--color-black);
  background-position: 0;
  background-size: cover;
  /* box-shadow: var(--box-shadow); */
}
.slide button:hover figure,
.slide button:focus figure {
  transform: scale(1.025);
}
.slide button span {
  text-align: left;
  font-size: var(--font-size);
  font-weight: 400;
  word-break: break-word;
  margin-top: 1rem;
}

.videoPlayer {
  position: relative;
  width: 100%;
  height: 426px;
  border-radius: var(--radius-small);
  max-width: 100%;
  max-height: 100vh;
  background: black;
  overflow: hidden;
  filter: grayscale(100%);
  padding: 0;
  margin: 0 auto 1rem;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  height: auto;
  width: auto;
  display: flex;
  background: transparent;
  padding: 0.75rem 0.75rem 0.75rem 3rem;
  border: none;
}
.closeButton div {
  position: relative;
  height: auto;
  width: auto;
  display: flex;
  background: none;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: var(--radius-full);
  font-size: var(--font-size);
  font-weight: 400;
  cursor: pointer;
  color: var(--color-bg-white);
  background: var(--color-text-black);
  align-items: center;
  justify-content: center;
}
.closeButton button svg {
  width: 1.25rem;
  height: 1.25rem;
  fill: var(--color-black);
}

@media screen and (max-width: 768px) {
  .slide button figure {
    width: 16vw;
    height: 16vw;
  }

  .videoPlayer {
    height: 52vw;
    width: 100%;
  }
}
@media screen and (max-width: 576px) {
  .slide button {
    padding: 1rem 0.5rem;
  }
  .slide button figure {
    width: 20vw;
    height: 20vw;
  }
  .slide button span {
    font-size: 0.6rem;
  }

  .closeButton button {
    top: 0.35rem;
    right: 0.35rem;
    padding: 0.5rem 0.5rem;
    font-size: 0.8rem;
    border-radius: 0.75rem;
    gap: 0.25rem;
  }
  .closeButton button svg {
    width: 1.15rem;
    height: 1.15rem;
  }
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  vertical-align: middle;
  justify-content: space-between;
  padding: 0 1rem;
  right: 0;
  left: 0;
  background: var(--background-body);
  z-index: 999;
}
.header svg {
  width: 1.25rem;
  height: 1.25rem;
}

.buttonsContainer {
  display: flex;
  gap: 0.75rem;
}
.buttonsContainer button {
  border: none;
  height: 4rem;
  padding: 0;
  gap: 0.5rem;
  background: transparent;
  font-size: var(--font-size);
  font-weight: 400;
  display: flex;
  align-items: center;
  color: var(--color-text-black);
}
.buttonsContainer button:hover,
.buttonsContainer button:focus {
  opacity: 1 !important;
}
.buttonsContainer button figure {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
.buttonsContainer button:hover figure,
.buttonsContainer button:focus figure {
  background: var(--background-surface);
}

.buttonArrowClose {
  opacity: var(--opacity);
  transform: rotate(90deg);
}
.buttonArrowClose:hover {
  opacity: 1;
}

.logo {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.containerSettings {
  min-width: 280px;
  display: flex;
  gap: 2rem;
  flex-direction: column;
}
.containerSettings h3 {
  font-size: var(--font-size);

  margin: 0 0 0.5rem 0;
}
.containerSettings div {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}
.containerSettings button,
.containerSettings a {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--size-small);
  font-size: var(--font-size);
  font-weight: 400;
  background: var(--background-surface);
  color: var(--color-text-black);
  border-radius: var(--radius);
  padding: 0 0 0 1rem;
  gap: 1rem;
}
.containerSettings select {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .buttonHideMobile {
    display: none !important;
  }
}

@media screen and (max-width: 576px) {
  .header {
    padding: 0 0.5rem;
  }
}

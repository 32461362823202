.container {
  display: inline-grid;
  grid-template-columns: 22px auto;
  width: auto;
  padding: 2.5rem 3rem;
  margin: 2rem;
  height: auto;
  color: var(--color-text-black);
  font-size: var(--font-size);
  border-radius: 2rem;
  transition: var(--transition);
  text-align: left;
  gap: 2rem;
  background: var(--background-surface);
  max-width: var(--max-size-container-2);
}
.container svg {
  width: 22px;
  height: 22px;
  color: var(--color-text-black);
}

@media screen and (max-width: 576px) {
  .container {
    grid-template-columns: 1fr;
    padding: 2.5rem;
    gap: 1rem;
    font-size: var(--font-size-small);
    max-width: calc(var(--max-size-container));
    margin: 1rem;
  }
}

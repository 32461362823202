/* Cards */
.cards {
  display: flex;
  grid-gap: 1.5rem;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 4rem;
}
.cards a {
  position: relative;
  margin: 0;
  padding: 1rem 2rem;
  width: auto;
  color: inherit;
  text-decoration: none;
  color: var(--color-black);
  top: 0;
  transition: ease 0.25s;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  background-color: var(--color-white);
  border-radius: var(--radius);
}
.cards a:hover,
.cards a:focus {
  top: -3px;
}

.cards p {
  margin-bottom: 0 !important;
  padding: 0;
}
.cards svg {
  width: 1.5rem;
  height: 1.5rem;
}

@media screen and (max-width: 576px) {
  .cards {
    grid-gap: 1rem;
  }
  .cards a {
    max-width: 100%;
  }
  .cards svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.cookiesPopup {
  position: fixed;
  bottom: 0;
  right: 1rem;
  left: unset;
  display: flex;
  width: 100%;
  max-width: 20rem;
  gap: 1rem;
  background: var(--color-text-black);
  border-radius: 24px;
  padding: 2rem;
  text-align: center;
  transition: transform 0.5s ease-in-out;
  z-index: 1000;
  align-items: center;
  flex-direction: column;
  box-shadow: var(--box-shadow);
}
.cookiesPopup div {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}
.cookiesPopup p {
  color: var(--color-bg-white);
  font-size: var(--font-size);
}

.slideUp {
  transform: translateY(-1rem);
}
.slideDown {
  transform: translateY(100%);
}

.cookiesPopup button {
  background: var(--color-bg-white);
  color: var(--color-text-black);
  border: none;
  padding: 10px 20px;
  border-radius: var(--radius-small);
  cursor: pointer;
  font-size: var(--font-size-small);
}

.cookiesPopup a {
  font-size: var(--font-size-small);
}

.container {
  margin: 2rem auto;
  max-width: var(--max-size-container-3);
  width: calc(100% - 4rem);
  background: var(--background-surface);
  border-radius: 2rem;
}
.containerBanner {
  padding: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  text-wrap: balance;
}

.containerBanner h3 {
  margin-bottom: 2rem;
  font-size: var(--font-size-2);
  color: var(--color-text-black);
}
.containerBanner p {
  margin-bottom: 0.75rem;
  font-size: var(--font-size-big);
  color: var(--color-text-black);
}

.buttonsContainer {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

@media screen and (max-width: 576px) {
  .container {
    width: calc(100% - 2rem);
  }
  .containerBanner h3 {
    font-size: var(--font-size-3);
    margin-bottom: 1.5rem;
  }
  .containerBanner p {
    font-size: var(--font-size-small);
  }
  .containerBanner {
    padding: 3rem 2rem;
    margin: 1rem;
  }
}

.container {
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
  text-align: left;
  font-size: 0;
}
.container ul {
  width: 100%;
  padding-left: 1.25rem;
}
.container ul li a {
  float: left;
  padding: 0.2rem 0;
  font-size: var(--font-size);
  font-weight: 400;
  width: 100%;
  color: var(--color-text-black);
  opacity: var(--opacity);
}
.container ul li a:focus,
.container ul li a:hover {
  opacity: 1;
}
.container ul li:first-child a {
  margin-top: 0.5rem;
}

@media screen and (min-width: 576px) {
  .container ul {
    padding-left: 1.25rem;
  }
}

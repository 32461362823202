.container {
  transition: var(--transition-hover);
}
.container h2,
.container p {
  color: var(--color-black) !important;
}

.card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 3rem;
  padding: 3rem 0;
  color: var(--color-black);
}
.card li {
  display: flex;
  width: 100%;
  max-width: 18rem;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 2rem;
  background-color: var(--color-white);
  padding: 2rem;
}
.card li figure {
  display: flex;
  height: 5rem;
  width: 5rem;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}
.card li h3 {
  margin-top: 1.5rem;
  word-break: break-word;
  font-size: 1.25rem;
}
.card li p {
  font-size: var(--font-size);
}
.card li svg {
  width: 2rem;
  height: 2rem;
}

@media screen and (max-width: 768px) {
  .card {
    grid-gap: 1rem;
  }
  .card li figure {
    height: 4rem;
    width: 4rem;
  }
  .card li svg {
    width: 1.75rem;
    height: 1.75rem;
  }
  .card li p {
    font-size: var(--font-size-small);
  }
}

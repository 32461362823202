.container {
  display: grid;
  gap: 0;
  padding: 0;
  margin: 0 -1rem;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
}
.container li button {
  position: relative;
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  padding: 1rem;
  width: 100%;
  border: 0;
  margin: 0;
  background: transparent;
  color: var(--color-black);
  border-radius: var(--radius);
  transition: var(--transition-hover);
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  font-weight: 400;
}
.container li button:hover,
.container li button:focus {
  background: var(--color-black-translucent);
}

.container li button img {
  width: 3.5rem;
  background: var(--color-white);
  transition: var(--transition-hover);
  border-radius: 0.5rem;
  filter: grayscale(100%);
}
.container li button span {
  text-align: left;
  font-size: var(--font-size);
}
.container li button span:first-of-type {
  opacity: var(--opacity);
  font-size: var(--font-size-small);
}

.grid5 {
  grid-template-columns: repeat(5, 1fr);
}
.grid4 {
  grid-template-columns: repeat(4, 1fr);
}
.grid3 {
  grid-template-columns: repeat(3, 1fr);
}
.grid2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid5 li button,
.grid4 li button,
.grid3 li button {
  gap: 1rem;
  flex-direction: column;
  align-items: start;
}
.grid5 li button img,
.grid4 li button img,
.grid3 li button img {
  width: 100%;
}

.trendsApps li button img {
  border-radius: 1.5rem;
}
.trendsCode li button img {
  border-radius: 0.25rem;
}

.trendsSocial li button span,
.trendsCode li button span {
  line-break: anywhere;
}

.trendsShopping li button img {
  padding: 1rem;
}
.trendsLocal li button img {
  padding: 0.75rem;
}

@media screen and (max-width: 768px) {
  .container {
    grid-template-columns: 1fr 1fr;
  }

  .title h3 {
    font-size: var(--font-size);
  }

  .grid5 {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 576px) {
  .trendsCode {
    grid-template-columns: 1fr;
  }

  .grid5 {
    grid-template-columns: repeat(2, 1fr);
  }

  .trendsHome li button img,
  .trendsAI li button img,
  .trendsWeb li button img,
  .trendsLocal li button img,
  .trendsCode button img {
    width: 52px !important;
  }

  .container li button {
    padding: 0.5rem 1rem;
  }
}

.customSelect {
  position: relative;
  display: inline-block;
  width: 100%;
}
.customSelect select {
  position: relative;
  cursor: pointer;
  font-size: var(--font-size);
  font-weight: 400;
  height: var(--size-small);
  padding: 0 1.5rem 0 3.25rem;
  line-height: 1.2rem;
  margin: 0;
  border-radius: var(--radius);
  border: none;
  color: inherit;
  background-repeat: no-repeat;
  background-size: 1.45rem;
  background-position: top 50% right 1rem;
  background-color: var(--background-surface);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.customSelect select:hover,
.customSelect select:focus {
  opacity: 1;
}
.label {
  font-size: 0;
  display: none;
}
.customSelect svg {
  position: absolute;
  top: 0.6rem;
  left: 1rem;
  width: 1.25rem;
  height: 1.25rem;
  color: var(--color-text-black);
  z-index: 1;
}

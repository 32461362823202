.footer {
  margin: 0 auto;
  width: 100%;
  max-width: var(--max-size-container-3);
}

.container {
  position: relative;
  display: flex;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 2rem;
  padding: 1rem 0 2rem;
}

.container ul {
  display: flex;
  gap: 1.25rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  color: var(--color-text-black);
}
.container ul li {
  font-size: 0;
  display: flex;
  gap: 1rem;
  text-align: left;
}
.container ul li:last-child {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.container ul li a,
.container p {
  font-size: var(--font-size-small);
  color: var(--color-text-black);
  font-weight: 400;
  text-align: center;
}

.container ul li a:has(svg) {
  font-size: 0;
}

.container ul li a svg {
  width: 0.875rem;
  height: 0.875rem;
  color: var(--color-text-black);
  transition: var(--transition);
}
.container ul li a:hover svg,
.container ul li a:focus svg {
  fill: var(--color-text-black);
}

.container figure svg {
  width: 4rem;
  height: 4rem;
  fill: var(--color-text-black);
  transition: var(--transition);
  opacity: 1;
}
.container figure:hover svg,
.container figure:focus svg {
  fill: var(--color-text-black);
}

.container p a,
.container a {
  transition: var(--transition);
}

.container a:hover,
.container a:focus {
  color: var(--color-text-black);
}

@media screen and (max-width: 1024px) {
  .container ul {
    gap: 1rem;
  }
  .container p,
  .container ul li a {
    font-size: 0.8rem;
  }
}

.button {
  display: inline-flex;
  gap: 0.75rem;
  margin: 0.5rem 0 0;
  width: auto;
  padding: 1rem 2rem;
  font-size: var(--font-size);
  border: none;
  border-radius: var(--radius-full);
  transition: var(--transition);
  justify-content: center;
  align-items: center;
}
.button:hover {
  transform: translateY(-1px);
}

.button svg {
  width: 1.125rem;
  height: 1.125rem;
}

.black {
  background: var(--color-text-black);
  color: var(--color-bg-white);
}

@media screen and (max-width: 576px) {
  .button {
    gap: 0.5rem;
    padding: 0.75rem 1.75rem;
    font-size: var(--font-size);
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease;
  z-index: 999;
  backdrop-filter: blur(0.75rem);
}
.modal-overlay.open {
  opacity: 1;
  pointer-events: auto;
}

.modal {
  position: relative;
  background: var(--color-bg-white);
  padding: 2.5rem;
  border-radius: var(--radius-big);
  box-shadow: var(--shadow-2);
  opacity: 0;
  transform: scale(0.9);
  transition: transform 0.25s ease, opacity 0.25s ease;
  color: var(--color-text-black);
  z-index: 999;
}
.modal.open {
  transform: scale(1);
  opacity: 1;
  outline: none;
}

.modalTitle {
  font-size: 1.5rem;
  margin: 0 1.25rem 2rem;
  text-align: center;
}

.modal-close {
  border: none;
  cursor: pointer;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  width: var(--size-large);
  height: var(--size-large);
  font-size: 0;
  justify-content: end;
  opacity: var(--opacity);
  border-radius: var(--radius-full);
  background: transparent !important;
}
.modal-close:hover {
  background: transparent !important;
  opacity: 1;
}

.container select {
  color: var(--color-text-black);
  background: var(--background-surface);
}
.container select:hover,
.container select:focus {
  opacity: 1;
}
.container svg {
  color: var(--color-text-black);
}

@media screen and (max-width: 576px) {
  .container select {
    font-size: 0.8rem;
  }
}

.active {
  background: var(--color-surface-secondary) !important;
  opacity: 1 !important;
}

.nav li {
  display: inline-block;
}
.nav svg {
  width: 1.25rem;
  height: 1.25rem;
}

/* Vertical */
.vertical ul {
  position: relative;
  gap: 0.25rem;
  display: grid;
  margin: -1rem;
  grid-template-columns: repeat(3, 1fr);
}
.vertical a {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 0.5rem 0.75rem 0.5rem;
  gap: 0.25rem;
  width: 100%;
  border-radius: 1rem;
  background: transparent;
  font-size: var(--font-size-small);
  font-weight: 400;
}
.vertical a:hover,
.vertical a:focus {
  background: var(--color-black-translucent);
}
.vertical svg {
  margin-top: 1rem;
}

/* Cards */
.cards ul {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 0;
  margin: 2rem auto 0;
  padding: 0;
  max-width: var(--max-size-container);
}
.cards a {
  opacity: 1;
  padding: 1.125rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1.5rem;
  display: flex;
  font-size: var(--font-size);
  font-weight: 400;
  transition: var(--transition);
  width: 100%;
  background: transparent;
  color: var(--color-black);
}
.cards a:hover,
.cards a:focus {
  background: var(--color-black-translucent) !important;
}
.cards figure {
  display: flex;
}
.cards li {
  width: 100%;
}

/* Sidebar */
.sidebar {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}
.sidebar ul {
  display: flex;
  flex-direction: column;
  padding: 0.75rem 0.75rem 2rem 0.75rem;
}
.sidebar a {
  opacity: var(--opacity);
  padding: 0.6875rem 0.75rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  display: flex;
  font-size: var(--font-size-small);

  width: 100%;
  color: var(--color-text-black);
  background: transparent;
  border-radius: var(--radius);
}
.sidebar a:hover,
.sidebar a:focus {
  transition: var(--transition);
  background: var(--color-surface-secondary);
}
.sidebar figure {
  display: flex;
}
.sidebar li {
  display: inline-block;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .cards ul {
    grid-template-columns: repeat(4, 1fr);
  }
  .cards a {
    font-size: var(--font-size-small);
  }
}

@media screen and (max-width: 576px) {
  .cards ul {
    grid-template-columns: repeat(3, 1fr);
  }
}

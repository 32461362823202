.button {
  font-size: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 2.25rem;
  width: 2.25rem;
  background: var(--color-text-black);
  border-radius: var(--radius-full);
  border: 0;
}
.button:hover,
.button:focus {
  opacity: 1;
}
.button svg {
  width: 1.2rem;
  height: 1.2rem;
  color: var(--color-bg-white);
}

.buttonActive,
.buttonActive:hover,
.buttonActive:focus,
.buttonActive:active {
  background: var(--color-white);
}

.buttonListening {
  content: '';
  position: absolute;
  top: -1.5rem;
  left: 0;
  right: 0;
  width: 10rem;
  height: 10rem;
  margin: 0 auto;
  background: var(--color-black-translucent-2);
  border-radius: 100%;
  animation: pulse 1s infinite ease-in-out;
  z-index: 0;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
@keyframes pulse {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.transcriptContainer {
  max-width: 480px;
  min-width: 280px;
  width: 100%;
  padding: 1.5rem 0;
}

@media (max-width: 576px) {
  .button {
    height: 2rem;
    width: 2rem;
  }
  .button svg {
    width: 1.125rem;
    height: 1.125rem;
  }
}
